<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#relatorio-analitico">
        Relatório Analítico
        <i :class="$utilidade.getIcone('relatorioAnalitico') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-relatorio-analitico v-if="strTab == 'relatorio-analitico'"   :key="strTab+$root.$session.versao"/>
  </div>
</template>

<script>
 import EdRelatorioAnalitico from './partials/analitico.vue'

export default {
  name: "pageCadastroEmpresa",
  props: {

  },
  components: {
EdRelatorioAnalitico
  },
  inject: [],
  mounted() {

  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      strTab:'relatorio-analitico'
    };
  },
  watch: {},
  computed: {},
  methods: {

  },
};
</script>
