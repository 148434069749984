<template>
  <div>
    <ed-filter
      :key="'table-filter-' + $root.$session.versao"
      :filter="formFiltros"
      :config="{ filterName: 'Gerar Relatório', filterIcon: 'salvar' }"
      @filter="getRelatorio"
    >
      <template slot="table-filter">
        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formFiltros.intCadastroGeralId"
          label="Empresa"
          route="Cadastro/CadastroGeral"
          :filters="{
            intTipoCadastroGeralId: 58,
          }"
          rules="required"
          clearable
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formFiltros.intCadastroGeralUnidadeId"
          label="Unidade de Trabalho"
          route="Cadastro/CadastroGeralUnidade"
          :disabled="formFiltros.intCadastroGeralId ? false : true"
          :filters="{
            intCadastroGeralEmpresaId: formFiltros.intCadastroGeralId,
          }"
          rules="required"
          clearable
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formFiltros.intCadastroGeralSetorId"
          label="Setor"
          route="Cadastro/CadastroGeralSetor"
          :disabled="formFiltros.intCadastroGeralId ? false : true"
          :filters="{
            intCadastroGeralEmpresaId: formFiltros.intCadastroGeralId,
          }"
          clearable
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          label="Ano"
          v-model="formFiltros.intAnoBase"
          number
        />
      </template>
    </ed-filter>



    <ed-simple-table
      id="table-v0"
      class="table-v2 mt-1"
      small
      outlined
      borered
      v-if="formData && formData.total"
      :config="{registerIcon:'fas fa-file-csv',registerName:'Exportar para Excel'}"
      @register="exportar"
    >
      <thead>
        <tr>
          <th rowspan="2" width="200">NATUREZA DO EXAME</th>
          <th colspan="2">Nº ANUAL DE EXAMES REALIZADOS (NAER)</th>
          <th colspan="2">Nº DE RESULTADOS ANORMAIS (NRA)</th>
          <th colspan="2">NRA X 100 / NAER</th>
          <th colspan="2">Nº DE CAT</th>
          <th rowspan="2">INCIDÊNCIA</th>
          <th rowspan="2">PREVALÊNCIA</th>
        </tr>
        <tr>
          <th>{{ formFiltros.intAnoBase - 1 }}</th>
          <th>{{ formFiltros.intAnoBase }}</th>
          <th>{{ formFiltros.intAnoBase - 1 }}</th>
          <th>{{ formFiltros.intAnoBase }}</th>
          <th>{{ formFiltros.intAnoBase - 1 }}</th>
          <th>{{ formFiltros.intAnoBase }}</th>
          <th>{{ formFiltros.intAnoBase - 1 }}</th>
          <th>{{ formFiltros.intAnoBase }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="formData && formData.total.length == 0 && !loadingTable">
          <td valign="middle" colspan="11" align="center">
            Nenhum exame encontrado para o período selecionado
          </td>
        </tr>

        <template v-for="(setor, indexSetor) in formData.total">
          <tr :key="'setor-' + indexSetor">
            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.id }} ({{ setor.descricao }})
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.total_realizado_ano1 }}
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.total_realizado_ano2 }}
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.total_anormal_ano1 }}
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.total_anormal_ano2 }}
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.percentual_ano1 }}
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.percentual_ano2 }}
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.total_cat_ano1 }}
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.total_cat_ano2 }}
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.incidencia }}
            </td>

            <td
              valign="middle"
              style="background: #ceb94e; font-weight: bold"
            >
              {{ setor.prevalencia }}
            </td>
          </tr>

          <template v-for="(exame, indexExame) in setor.exames">
            <tr :key="'exame-' + indexSetor + indexExame">
              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.nome }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.total_realizado_ano1 }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.total_realizado_ano2 }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.total_anormal_ano1 }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.total_anormal_ano2 }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.percentual_ano1 }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.percentual_ano2 }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.total_cat_ano1 }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.total_cat_ano2 }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.incidencia }}
              </td>

              <td
                valign="middle"
                :style="
                  'color:' +
                  (exame.total_anormal_ano1 > 0 || exame.total_anormal_ano2 > 0
                    ? 'red'
                    : '')
                "
              >
                {{ exame.prevalencia }}
              </td>
            </tr>
          </template>
        </template>
        <tr v-if="formData.total.length">
          <td valign="middle" style="background: #cccccc; font-weight: bold">
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].total_geral_realizado_ano1 }}
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].total_geral_realizado_ano2 }}
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].total_geral_anormal_ano1 }}
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].total_geral_anormal_ano2 }}
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].percentual_geral_ano1 }}
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].percentual_geral_ano2 }}
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].total_geral_cat_ano1 }}
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].total_geral_cat_ano2 }}
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].total_incidencia }}
          </td>
          <td valign="middle" style="background: #cccccc; font-weight: bold">
            {{ formData.total[0].total_prevalencia }}
          </td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdFilter,
  EdInputText,
  EdInputAutoComplete,
  EdSimpleTable,
  EdButton
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdFilter,
    EdInputText,
    EdInputAutoComplete,
    EdSimpleTable,
    EdButton
  },
  inject: [],
  mounted() {
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      strTab: "relatorio-analitico",
      bind:{
        boolCarregado:false
      },
      formData:{},
      formFiltros: {
        intCadastroGeralId: null,
        intCadastroGeralUnidadeId: null,
        intCadastroGeralSetorId: null,
        intAnoBase: this.$utilidade.toDate(null, true, "YYYY"),
      },
    };
  },
  watch: {},
  computed: {},
  methods: {

    exportar() {
      let formFiltros = _.cloneDeep(this.formFiltros);
      formFiltros.boolExportar = true;

      this.$root.$request.open("Relatorio/RelatorioAnalitico/get", formFiltros)
    },

    getRelatorio() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Relatorio/RelatorioAnalitico", this.formFiltros)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            this.formData = objResponse.result
          }
        });
    },
  },
};
</script>
