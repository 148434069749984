<template>
  <div>
    <ed-relatorio-sesmt
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import EdRelatorioSesmt from "@/components/relatorio/sesmt/index";


export default {
  name: "pageCadastro",
  props: {},
  components:{
    EdRelatorioSesmt
  },
  mounted() {
  },
  created() {


  },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>

